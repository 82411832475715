<template>
    <div class="my_treat_page">
        <van-sticky :offset-top="0">
            <div class="page_top" style="background-color: #FAFAFA;">
                <van-search class="search_div" input-align="center" background="#fff" left-icon="none"   @search="onSearch(searchname)" v-model="searchname" placeholder="请输入会员/家属姓名/手机号" />
                <!-- <van-search class="search_div" input-align="center" background="#fff" left-icon="none"   @search="onSearchphone(searchphone)" v-model="searchphone" placeholder="请输入会员/家属手机号" /> -->
            </div>
        </van-sticky>
        <div class="treat_main_list"  >
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            v-if="otherList.length > 0"
            :offset="30"
            :immediate-check="false"
            @load="getOther"
            >
            <div class="one"  v-for="(item,index) in otherList" :key="index">
                <div class="name_appointment">
                    <div class="left">{{item.username}}</div>
                    
                </div>
                <div class="sex_age_phone">
                    <span>{{item.sex }}</span>
                    <span v-if="item.phone">{{(item.phone).replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}}</span>
                </div>

                <div style="display: flex;justify-content: center;">
                    <div @click="chooseOther(item)" style="background-color: #CF8C57;width: 100px;height: 38px;text-align: center;line-height: 38px;color: #fff;border-radius: 21px;">选择</div>
                </div>
            </div>
        </van-list>
        <div class="has_no_data" v-else>
            当前无会员数据
        </div>
        </div>
        
    </div>
</template>
<script>
import { wxtriagememberinfoqry ,wxtriageeditcardreceive} from '@/api/medical'
import { mapGetters } from 'vuex'
import { Toast,Dialog } from 'vant'
export default{
    computed: {
            ...mapGetters([
                'username','dispname','clinicid'
            ]),
        },
    data(){
        return{
            searchname:'',
            searchphone:'',
            treat:'',
            otherList:[],
            loading: false,
            finished: false,
            page:0,
        }
    },
    created(){
        
        this.$store.dispatch('hideOrShowNav', false)
        this.getOther()
    },

    methods:{
        
        getOther(){
            this.page += 1
            let data = {
                username: this.username,
                keyword:this.searchname,
                qrypage:this.page,
            }
            wxtriagememberinfoqry(data).then(response =>{ 
                this.loading = false
                if(response.response_body.count){

                    this.otherList =[...this.otherList, ...response.response_body.list] || []
                    if(this.page >= response.response_body.pages){
                        
                        this.finished = true
                    }
                }else{
                    this.finished = true
                    this.otherList = []
                }
            })
        },
        onSearch(key){
            this.page = 0
            this.otherList = []
            this.finished = false
            this.loading = false
            this.getOther(key)

        },
        onSearchphone(key){
            this.page = 0
            this.otherList = []
            this.finished = false
            this.loading = false
            this.getOther(key)

        },
        chooseOther(item){
            let that = this
            Dialog.confirm({
                title: '提示',
                message: `是否选择【${item.username}】患者进行赠送`,
                confirmButtonColor:'#CF8C57',
            }).then(() => {
                // on confirm
                
                let data = {
                    username:this.username,
                    uid:this.$route.query.uid,
                    userid:item.id,
                    oprtype:'1',
                }
   
                wxtriageeditcardreceive(data).then(response =>{
              
                    Toast({message:'赠送成功！',onClose:()=>{
                            // 跳转页面
                            // location.reload()
                            that.$router.go(-1)
                        }
                    
                     })
                }).catch((err) => {
                    // on cancel

                    Toast.fail(err.response_head.retinfo.retmsg)
                });
            })
            .catch(() => {
                // on cancel
            });
           
        }
    }
}
</script>
<style lang="scss" scoped>
.my_treat_page{
    margin-bottom: 0;
    min-height: 100vh;
    .page_top{
        background-color: unset;
    }
    .date_choose{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        margin-left: 10px;
        .page_top .date_choose .date_info{
            color: #ccc;
        }
    }
}
.van-search__content{
    background-color: #fff;
}
.page_top .search_div{
    width:100%;
}
.van-search__content{
    padding-left: unset;
}
.iconarrow-bottom-filling{
    width: 0;
    height: 0;
    border-top: 5px solid #ccc;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.bai {
        background-color: #ffffff;
    }
    .btnimg{
        width: 50px;
        height: 50px;
        position: fixed;
        right: 10px;
        bottom: 200px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .treat_main_list{

        .one{
           background: linear-gradient( 180deg, rgba(207,140,87,0.2) 0%, rgba(255,249,244,0) 20%) !important;
       }
       .name_appointment .left{
             width: 170px;
            white-space: nowrap;      
            overflow: hidden;         
            text-overflow: ellipsis;
       }
    }
    
</style>